import React from 'react'
import i18n from 'i18next'
import { useNavigate } from 'react-router-dom'
import {
  UqAppBar,
  UqToolbar,
  UqIconButton,
  UqTypography,
  UqLinearProgress,
  UqLink
} from '@uniqore/wrapper'
import ArrowBackIosRounded from '@mui/icons-material/ArrowBack'

interface NavBarProps {
  text?: string
  progressValue?: number
  showLanguageChange?: boolean
  navigateTo: string
}

const NavBar: React.FC<NavBarProps> = ({
  text,
  progressValue = 50,
  showLanguageChange = false,
  navigateTo
}) => {
  const navigate = useNavigate()

  return (
    <>
      <UqAppBar position="relative" elevation={0} color="inherit">
        <UqToolbar>
          <UqIconButton
            onClick={() => navigate(navigateTo)}
            color="inherit"
            size="medium"
            edge="start"
            sx={{ mr: 1 }}
          >
            <ArrowBackIosRounded />
          </UqIconButton>
          <UqTypography variant="subtitle1" marginhorizontal={0} sx={{ flexGrow: 1 }}>
            {text}
          </UqTypography>
          {showLanguageChange && (
            <UqTypography variant="caption" sx={{ fontSize: '16px' }}>
              <UqLink
                onClick={() => navigate(`/fi/date`)}
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  fontWeight: i18n.language === 'fi' ? 'bolder' : 'normal'
                }}
              >
                FI{` `}
              </UqLink>
              /
              <UqLink
                onClick={() => navigate(`/en/date`)}
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  fontWeight: i18n.language === 'en' ? 'bolder' : 'normal'
                }}
              >
                {` `}EN
              </UqLink>
            </UqTypography>
          )}
        </UqToolbar>
      </UqAppBar>
      <UqLinearProgress value={progressValue} variant="determinate" color="primary" />
    </>
  )
}

export default NavBar
