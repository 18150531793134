import React, { useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useTypedSelector } from 'Store'
import { Layout, Image, FlowNav } from '@uniqore/module'
import { UqTypography, UqButton, UqAppBar, UqToolbar, UqIconButton, UqLink } from '@uniqore/wrapper'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'

const Onboarding: React.FC = () => {
  const { t } = useTranslation()
  const { lang } = useParams()
  const navigate = useNavigate()
  const availableDates = useTypedSelector(state => state.airtableData.availableDates)

  useEffect(() => {
    if (lang === 'fi' || lang === 'en') {
      i18n.changeLanguage(lang)
    } else {
      i18n.changeLanguage('fi')
      navigate(`/fi`)
    }
  }, [lang])

  return (
    <Layout
      containerBackground="#ECF6ED"
      top={
        <UqAppBar position="relative" elevation={0} color="inherit">
          <UqToolbar>
            <UqIconButton
              href={'https://www.uniqore.com/'}
              color="inherit"
              size="small"
              edge="start"
              sx={{ mr: 1 }}
            >
              <ArrowBackIosRoundedIcon />
            </UqIconButton>
            <UqTypography variant="subtitle1" marginhorizontal={0} sx={{ flexGrow: 1 }}>
              uniqore.com
            </UqTypography>
            <UqTypography variant="caption" sx={{ fontSize: '16px' }}>
              <UqLink
                onClick={() => navigate(`/fi`)}
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  fontWeight: i18n.language === 'fi' ? 'bolder' : 'normal'
                }}
              >
                FI{` `}
              </UqLink>
              /
              <UqLink
                onClick={() => navigate(`/en`)}
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  fontWeight: i18n.language === 'en' ? 'bolder' : 'normal'
                }}
              >
                {` `}EN
              </UqLink>
            </UqTypography>
          </UqToolbar>
        </UqAppBar>
      }
      bottom={
        <FlowNav
          actionDirection="column"
          actionComponents={[
            <UqButton
              key={1}
              fullWidth
              color="secondary"
              size="large"
              variant="contained"
              disabled={Object.keys(availableDates).length === 0}
              onClick={() => navigate(`/${lang}/date`)}
              marginVertical={4}
            >
              {t('onboarding_button_1')}
            </UqButton>,
            <UqButton
              key={2}
              href={`${process.env.REACT_APP_UNIQORE_DEMO_URL}${i18n.language}/welcome`}
              fullWidth
              color="secondary"
              size="large"
              variant="text"
              marginVertical={4}
            >
              {t('onboarding_button_2')}
            </UqButton>
          ]}
        />
      }
    >
      <Image
        src="https://uploads-ssl.webflow.com/62862937efdfeff32720287c/6295e5c17df05301ddf3fc06_logo-3d.svg"
        width={60}
        center
        marginTop={64}
        marginBottom={24}
      />
      <UqTypography variant="body2" color="text.secondary" align="center">
        {t('onboarding_desc')}
      </UqTypography>
    </Layout>
  )
}

export default Onboarding
