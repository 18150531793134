import React, { useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
import { useTypedSelector } from 'Store'
import { Layout, Image, FlowNav } from '@uniqore/module'
import {
  UqBox,
  UqButton,
  UqList,
  UqListItem,
  UqListItemText,
  UqListItemAvatar,
  UqAvatar,
  UqListItemIcon,
  UqTypography
} from '@uniqore/wrapper'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded'

const Confirmation: React.FC = () => {
  const { t } = useTranslation()
  const { lang } = useParams()
  const navigate = useNavigate()
  const { date, time } = useTypedSelector(state => state.bookingDetails)

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  useEffect(() => {
    if (!time) {
      navigate(`/${lang}`)
    }
  }, [time])

  return (
    <Layout
      containerBackground="#ECF6ED"
      bottom={
        <FlowNav
          actionComponents={[
            <UqButton
              key={1}
              href={'https://www.uniqore.com/'}
              fullWidth
              variant="text"
              size="large"
              target="_blank"
              rel="noopener noreferrer"
            >
              uniqore.com
            </UqButton>
          ]}
        />
      }
    >
      <Image
        src="https://assets.website-files.com/62862937efdfeff32720287c/630dc8979f2fad3d67f2eeb5_Space-Verified-uniqore.svg"
        width={60}
        center
        marginTop={24}
      />
      <UqTypography variant="h6" color="text.primary" align="center" marginTop={24}>
        {t('confirmation_title')}
      </UqTypography>
      <UqTypography variant="body2" color="text.secondary" align="center">
        {t('confirmation_desc')}
      </UqTypography>
      <UqBox
        marginTop={58}
        marginBottom={24}
        sx={{
          backgroundColor: '#ECF6ED4D',
          border: `1px solid #E3F1E3`,
          borderRadius: '4px',
          padding: '8px'
        }}
      >
        <UqList disablePadding>
          <UqListItem dense>
            <UqListItemAvatar>
              <UqAvatar src="https://media-exp1.licdn.com/dms/image/C4D03AQHdZT6l0o6_Sg/profile-displayphoto-shrink_200_200/0/1614175727249?e=1657756800&v=beta&t=1mSeMZRQoIMEESceN0nXSV4tan6NjtYPrNtSjJiPLGE" />
            </UqListItemAvatar>
            <UqListItemText
              primary={`Tomi Pyrhönen (${t('summary_organizer_primary')})`}
              secondary={t('summary_organizer_secondary')}
              primaryTypographyProps={{ color: 'text.primary' }}
              secondaryTypographyProps={{ color: 'text.secondary' }}
            />
          </UqListItem>
          <UqListItem dense>
            <UqListItemIcon>
              <InfoRoundedIcon sx={{ color: theme => theme.palette.text.disabled }} />
            </UqListItemIcon>
            <UqListItemText
              primary={t('summary_meeting_info_primary')}
              secondary={t('summary_meeting_info_secondary')}
              primaryTypographyProps={{ color: 'text.primary' }}
              secondaryTypographyProps={{ color: 'text.secondary' }}
            />
          </UqListItem>
          <UqListItem dense>
            <UqListItemIcon>
              <CalendarTodayRoundedIcon sx={{ color: theme => theme.palette.text.disabled }} />
            </UqListItemIcon>
            <UqListItemText
              primary={`${date} ${t('summary_meeting_time')} ${time}`}
              primaryTypographyProps={{ color: 'text.primary' }}
            />
          </UqListItem>
          <UqListItem dense>
            <UqListItemIcon>
              <VideocamRoundedIcon sx={{ color: theme => theme.palette.text.disabled }} />
            </UqListItemIcon>
            <UqListItemText
              primary={t('summary_info_primary')}
              primaryTypographyProps={{ color: 'text.primary' }}
            />
          </UqListItem>
        </UqList>
      </UqBox>
    </Layout>
  )
}

export default Confirmation
