import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AirtableRecords } from 'Types/airtable'
import { format, parseISO, parse, compareAsc, isToday, isPast } from 'date-fns'

interface TimeSchema {
  [time: string]: {
    airtableRowId: string
  }
}

interface BookingDateSchema {
  [date: string]: TimeSchema
}

interface AirtableData {
  datesFetched: boolean
  availableDates: BookingDateSchema
}

const initialState: AirtableData = {
  datesFetched: false,
  availableDates: {}
}

const airtableDataSlice = createSlice({
  name: 'airtableData',
  initialState,
  reducers: {
    initAvailableDates: (state, action: PayloadAction<AirtableRecords[]>) => {
      const setDates: BookingDateSchema = {}

      for (const record of action.payload) {
        if (!record.fields.Bookings) {
          const formattedDate = format(parseISO(record.fields['Time slots']), 'dd.MM.yyyy')
          const formattedTime = format(parseISO(record.fields['Time slots']), 'HHmm')

          const thisDate = parse(formattedDate, 'dd.MM.yyyy', new Date())
          const today = isToday(thisDate)
          const past = isPast(thisDate)

          // Do not set date if date is today or in the past
          if (today || past) continue

          setDates[formattedDate] = {
            ...setDates[formattedDate],
            [formattedTime]: {
              airtableRowId: record.id
            }
          }
        }
      }

      // Sort dates
      const sortDates: Date[] = []
      Object.keys(setDates).forEach(value => {
        sortDates.push(parse(value, 'dd.MM.yyyy', new Date()))
      })
      sortDates.sort(compareAsc)

      // Once dates are sorted, add times to sorted date
      const sortedDates: BookingDateSchema = {}
      sortDates.forEach(date => {
        const result = format(date, 'dd.MM.yyyy')
        sortedDates[result] = setDates[result]
      })

      // Order times
      const datesWithSortedDatesAndTimes: BookingDateSchema = {}
      Object.entries(sortedDates).forEach(([date, time]) => {
        const sortedTimes: TimeSchema = {}
        const sortTimes = Object.keys(time).sort((a, b) => Number(a) - Number(b))
        sortTimes.forEach(sortTime => {
          const formatTime = `${sortTime.slice(0, 2)}:${sortTime.slice(2)}`
          sortedTimes[formatTime] = time[sortTime]
        })
        datesWithSortedDatesAndTimes[date] = sortedTimes
      })

      state.availableDates = datesWithSortedDatesAndTimes
      state.datesFetched = true
    }
  }
})

export const { initAvailableDates } = airtableDataSlice.actions
export default airtableDataSlice.reducer
