import { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { store } from 'Store'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import Theme from 'Theme'
import { ApolloProvider } from '@apollo/client'
import PublicClient from 'Api/publicClient'
import * as serviceWorker from './serviceWorker'
import { App } from './App'
import './i18n'

const render = () => {
  ReactDOM.render(
    <Suspense fallback={null}>
      <StrictMode>
        <Provider store={store}>
          <ApolloProvider client={PublicClient}>
            <ThemeProvider theme={Theme}>
              <App />
            </ThemeProvider>
          </ApolloProvider>
        </Provider>
      </StrictMode>
    </Suspense>,
    document.getElementById('root')
  )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
