import { combineReducers } from '@reduxjs/toolkit'

import airtableData from 'Features/airtableData'
import bookingDetails from 'Features/bookingDetails'
import snackbar from 'Features/snackbar'

const rootReducer = combineReducers({
  airtableData,
  bookingDetails,
  snackbar
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
