import React, { useState, useEffect } from 'react'
import i18n from 'i18next'
import { TFunction, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { Layout, FlowNav, Image } from '@uniqore/module'
import {
  UqAppBar,
  UqToolbar,
  UqLink,
  UqTypography,
  UqTextField,
  UqRating,
  UqBackdrop,
  UqCircularProgress,
  UqDivider,
  UqButton,
  UqBox,
  UqDialog,
  UqDialogTitle,
  UqDialogContent,
  UqDialogActions
} from '@uniqore/wrapper'
import { editSnackbar } from 'Features/snackbar'

const SERVICE_ENGAGEMENT = gql`
  query serviceEngagement($id: ID!) {
    serviceEngagement(id: $id) {
      metadata {
        key
        value
      }
    }
  }
`

const SEND_FEEDBACK = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

const getDateAndTime = (
  feedbackGivenDate: string,
  t: TFunction<'translation', undefined>
): string => {
  const formattedDate = format(parseISO(feedbackGivenDate), 'dd.MM.yyyy')
  const formattedTime = format(parseISO(feedbackGivenDate), 'HH:mm')
  return `${formattedDate} ${t('summary_meeting_time')} ${formattedTime}`
}

const Feedback: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { lang, engagement } = useParams()

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [feedbackGivenDate, setFeedbackGivenDate] = useState<string>('')
  const [isFeedbackSent, setIsFeedbackSent] = useState<boolean>(false)
  const [userName, setUserName] = useState<string>('')
  const [ratingValue, setRatingValue] = useState<number | null>(null)
  const [feedback, setFeedback] = useState<string>('')
  const [currentFeedback, setCurrentFeedback] = useState<string>('')

  const { loading } = useQuery(SERVICE_ENGAGEMENT, {
    variables: {
      id: engagement
    },
    onCompleted(result) {
      if (result.serviceEngagement) {
        const findName = result.serviceEngagement.metadata.find(
          (m: { key: string; value: string }) => m.key === 'name'
        )
        const findFeedbackDate = result.serviceEngagement.metadata.find(
          (m: { key: string; value: string }) => m.key === 'feedbackPublic'
        )
        if (findName) {
          setUserName(findName.value)
        }
        if (findFeedbackDate) {
          setFeedbackGivenDate(findFeedbackDate.value.date)
        }
      } else {
        navigate('/')
      }
    },
    onError() {
      navigate('/')
    }
  })

  const [sendFeedback, { loading: feedbackLoading }] = useMutation(SEND_FEEDBACK, {
    onCompleted(result) {
      if (result && !result.ingestForm.errors) {
        setIsFeedbackSent(true)
      } else {
        dispatch(
          editSnackbar({
            message: t('common_error'),
            type: 'error'
          })
        )
      }
    },
    onError() {
      dispatch(
        editSnackbar({
          message: t('common_error'),
          type: 'error'
        })
      )
    }
  })

  const handleSubmit = () => {
    const setFeedbackDetails = {
      engagementId: engagement,
      ratingValue,
      feedback
    }

    sendFeedback({
      variables: {
        id: process.env.REACT_APP_SEND_FEEDBACK_ID,
        form: setFeedbackDetails
      }
    })
  }

  return (
    <>
      {!feedbackGivenDate ? (
        <>
          {!isFeedbackSent ? (
            <Layout
              top={
                <UqAppBar position="relative" elevation={0} color="inherit">
                  <UqToolbar>
                    <UqTypography marginhorizontal={0} sx={{ flexGrow: 1 }} />
                    <UqTypography variant="caption" sx={{ fontSize: '16px' }}>
                      <UqLink
                        onClick={() => i18n.changeLanguage('fi')}
                        sx={{
                          cursor: 'pointer',
                          textDecoration: 'none',
                          fontWeight: i18n.language === 'fi' ? 'bolder' : 'normal'
                        }}
                      >
                        FI{` `}
                      </UqLink>
                      /
                      <UqLink
                        onClick={() => i18n.changeLanguage('en')}
                        sx={{
                          cursor: 'pointer',
                          textDecoration: 'none',
                          fontWeight: i18n.language === 'en' ? 'bolder' : 'normal'
                        }}
                      >
                        {` `}EN
                      </UqLink>
                    </UqTypography>
                  </UqToolbar>
                </UqAppBar>
              }
              containerBackground="#ECF6ED"
              bottom={
                <FlowNav
                  actionComponents={[
                    {
                      text: !ratingValue
                        ? t('feedback_button_give_rating')
                        : t('feedback_button_send_rating'),
                      color: 'primary',
                      onClick: handleSubmit,
                      disabled: !ratingValue
                    }
                  ]}
                />
              }
            >
              <Image
                width={80}
                marginTop={24}
                center
                src="https://assets.website-files.com/62862937efdfeff32720287c/630dc89704d2d6803b6f4a3b_Space-Feedback-uniqore.svg"
              />
              <UqTypography variant="h6" color="text.primary" align="center" marginTop={24}>
                {`${t('feedback_title')} ${userName}!`}
              </UqTypography>
              <UqDivider variant="fullWidth" light marginVertical={16} />
              <UqTypography variant="body2" color="text.primary" align="center">
                {t('feedback_desc')}
              </UqTypography>
              <UqTypography variant="caption" color="text.secondary" align="center" component="div">
                {t('feedback_rating')}
              </UqTypography>
              <div style={{ textAlign: 'center' }}>
                <UqRating
                  value={Number(ratingValue)}
                  onChange={(_e, value) => {
                    setRatingValue(value)
                  }}
                  size="large"
                  marginVertical={16}
                />
              </div>
              {!feedback ? (
                <UqButton
                  variant="text"
                  color="secondary"
                  size="large"
                  fullWidth
                  onClick={() => setDialogOpen(true)}
                >
                  {t('feedback_add_feedback')}
                </UqButton>
              ) : (
                <UqBox
                  sx={{
                    backgroundColor: '#ECF6ED4D',
                    border: `1px solid #E3F1E3`,
                    borderRadius: '4px',
                    padding: '8px'
                  }}
                >
                  <UqTypography
                    variant="subtitle2"
                    align="center"
                    color="text.disabled"
                  >{`"${feedback}"`}</UqTypography>
                  <UqTypography
                    variant="subtitle2"
                    color="secondary.main"
                    align="center"
                    marginTop={8}
                    onClick={() => setDialogOpen(true)}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    {t('feedback_edit_feedback')}
                  </UqTypography>
                </UqBox>
              )}
            </Layout>
          ) : (
            <Layout
              containerBackground="#ECF6ED"
              bottom={
                <FlowNav
                  actionComponents={[
                    <UqButton
                      key={1}
                      href={'https://www.uniqore.com/'}
                      fullWidth
                      variant="text"
                      size="large"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      uniqore.com
                    </UqButton>
                  ]}
                />
              }
            >
              <Image
                src="https://assets.website-files.com/62862937efdfeff32720287c/630dc8974e0ef54eba33a440_Space-Success-uniqore.svg"
                width={100}
                center
                marginTop={24}
              />
              <UqTypography variant="h6" color="text.primary" align="center" marginTop={24}>
                {t('feedback_thanks_title')}
              </UqTypography>
              <UqTypography variant="body2" color="text.secondary" align="center">
                {t('feedback_thanks_desc')}
              </UqTypography>
            </Layout>
          )}
        </>
      ) : (
        <Layout
          top={
            <UqAppBar position="relative" elevation={0} color="inherit">
              <UqToolbar>
                <UqTypography marginhorizontal={0} sx={{ flexGrow: 1 }} />
                <UqTypography variant="caption" sx={{ fontSize: '16px' }}>
                  <UqLink
                    onClick={() => i18n.changeLanguage('fi')}
                    sx={{
                      cursor: 'pointer',
                      textDecoration: 'none',
                      fontWeight: i18n.language === 'fi' ? 'bolder' : 'normal'
                    }}
                  >
                    FI{` `}
                  </UqLink>
                  /
                  <UqLink
                    onClick={() => i18n.changeLanguage('en')}
                    sx={{
                      cursor: 'pointer',
                      textDecoration: 'none',
                      fontWeight: i18n.language === 'en' ? 'bolder' : 'normal'
                    }}
                  >
                    {` `}EN
                  </UqLink>
                </UqTypography>
              </UqToolbar>
            </UqAppBar>
          }
          containerBackground="#ECF6ED"
          bottom={
            <FlowNav
              actionComponents={[
                <UqButton
                  key={1}
                  fullWidth
                  variant="contained"
                  size="large"
                  onClick={() => navigate('/')}
                >
                  {t('feedback_thanks_booking_button')}
                </UqButton>
              ]}
            />
          }
        >
          <Image
            src="https://assets.website-files.com/62862937efdfeff32720287c/630dc8974e0ef54eba33a440_Space-Success-uniqore.svg"
            width={80}
            center
            marginTop={24}
          />
          <UqTypography variant="h6" color="text.primary" align="center" marginTop={24}>
            {t('feedback_thanks_received_title')}
          </UqTypography>
          <UqTypography variant="body2" color="text.secondary" align="center">
            {`${t('feedback_thanks_received_desc_1')} ${getDateAndTime(feedbackGivenDate, t)}. ${t(
              'feedback_thanks_received_desc_2'
            )}`}
          </UqTypography>
        </Layout>
      )}

      <UqBackdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={loading || feedbackLoading}
      >
        <UqCircularProgress color="inherit" />
      </UqBackdrop>
      <UqDialog open={dialogOpen} fullWidth>
        <UqDialogTitle>{t('feedback_dialog_title')}</UqDialogTitle>
        <UqDialogContent>
          <UqTextField
            placeholder={t('feedback_dialog_input')}
            variant="outlined"
            size="medium"
            color="primary"
            rows={5}
            multiline
            fullWidth
            marginVertical={4}
            value={feedback}
            onChange={e => setFeedback(e.target.value)}
          />
        </UqDialogContent>
        <UqDialogActions>
          <UqButton
            variant="text"
            color="secondary"
            onClick={() => {
              setFeedback(currentFeedback)
              setDialogOpen(false)
            }}
          >
            {t('feedback_dialog_button_cancel')}
          </UqButton>
          <UqButton
            variant="contained"
            color="secondary"
            onClick={() => {
              setCurrentFeedback(feedback)
              setDialogOpen(false)
            }}
          >
            {t('feedback_dialog_button_submit')}
          </UqButton>
        </UqDialogActions>
      </UqDialog>
    </>
  )
}

export default Feedback
