import React, { useState, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { gql, useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { Layout, FlowNav } from '@uniqore/module'
import {
  UqBox,
  UqList,
  UqListItem,
  UqListItemText,
  UqListItemAvatar,
  UqAvatar,
  UqListItemIcon,
  UqTypography,
  UqTextField,
  UqSwitch,
  UqFormGroup,
  UqFormControlLabel,
  UqCollapse,
  UqBackdrop,
  UqCircularProgress
} from '@uniqore/wrapper'
import NavBar from 'Components/Common/NavBar'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded'
import { updateBookingDetails, BookingDetailsFormSchema } from 'Features/bookingDetails'
import { editSnackbar } from 'Features/snackbar'
import { validateEmail } from 'Util/helpers'

const SEND_BOOKING = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

const Summary: React.FC = () => {
  const { t } = useTranslation()
  const { lang } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    date,
    time,
    firstName,
    lastName,
    email,
    phone,
    participants,
    message,
    airtableAvailabilityRowId,
    participantsOpen,
    messageOpen,
    bookingFinished
  } = useTypedSelector(state => state.bookingDetails)

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  useEffect(() => {
    if (!time) {
      navigate(`/${lang}/time`)
    }
  }, [time])

  useEffect(() => {
    if (bookingFinished) {
      navigate(`/${lang}`)
      // Prevent double booking by reloading page (fetches new available dates from airtable)
      window.location.reload()
    }
  }, [])

  const [firstNameValid, setFirstNameValid] = useState<string>('')
  const [lastNameValid, setLastNameValid] = useState<string>('')
  const [phoneValid, setPhoneValid] = useState<string>('')
  const [emailValid, setEmailValid] = useState<string>('')

  const validateFields = () => {
    let valid = true
    if (firstName === '') {
      setFirstNameValid(t('common_error_input'))
      valid = false
    } else {
      setFirstNameValid('')
    }
    if (lastName === '') {
      setLastNameValid(t('common_error_input'))
      valid = false
    } else {
      setLastNameValid('')
    }
    if (phone === '') {
      setPhoneValid(t('common_error_input'))
      valid = false
    } else {
      setPhoneValid('')
    }
    if (!validateEmail(email)) {
      setEmailValid(t('common_error_input_email'))
      valid = false
    } else {
      setEmailValid('')
    }
    return valid
  }

  const [sendBooking, { loading }] = useMutation(SEND_BOOKING, {
    onCompleted(result) {
      if (result && !result.ingestForm.errors) {
        dispatch(updateBookingDetails({ key: 'bookingFinished', value: true }))
        navigate(`/${lang}/confirmation`)
      } else {
        dispatch(
          editSnackbar({
            message: t('common_error'),
            type: 'error'
          })
        )
      }
    },
    onError() {
      dispatch(
        editSnackbar({
          message: t('common_error'),
          type: 'error'
        })
      )
    }
  })

  const handleClickSendBooking = () => {
    if (validateFields()) {
      const setBookingDetails: BookingDetailsFormSchema = {
        date,
        time,
        firstName,
        lastName,
        email,
        phone,
        airtableAvailabilityRowId,
        language: i18n.language,
        participants: participantsOpen ? participants : '',
        message: messageOpen ? message : ''
      }
      sendBooking({
        variables: {
          id: process.env.REACT_APP_SEND_BOOKING_ID,
          form: setBookingDetails
        }
      })
    }
  }

  return (
    <>
      <UqBackdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <UqCircularProgress color="inherit" />
      </UqBackdrop>
      <Layout
        containerBackground="#ECF6ED"
        top={<NavBar text={t('summary_navbar')} navigateTo={`/${lang}/time`} progressValue={100} />}
        bottom={
          <FlowNav
            actionComponents={[
              {
                text: t('summary_button'),
                color: 'secondary',
                onClick: handleClickSendBooking,
                fullWidth: true,
                disabled: loading
              }
            ]}
          />
        }
      >
        <UqBox
          sx={{
            backgroundColor: '#ECF6ED4D',
            border: `1px solid #E3F1E3`,
            borderRadius: '4px',
            padding: '8px'
          }}
        >
          <UqList disablePadding>
            <UqListItem dense>
              <UqListItemAvatar>
                <UqAvatar src="https://media-exp1.licdn.com/dms/image/C4D03AQHdZT6l0o6_Sg/profile-displayphoto-shrink_200_200/0/1614175727249?e=1657756800&v=beta&t=1mSeMZRQoIMEESceN0nXSV4tan6NjtYPrNtSjJiPLGE" />
              </UqListItemAvatar>
              <UqListItemText
                primary={`Tomi Pyrhönen (${t('summary_organizer_primary')})`}
                secondary={t('summary_organizer_secondary')}
                primaryTypographyProps={{ color: 'text.primary' }}
                secondaryTypographyProps={{ color: 'text.secondary' }}
              />
            </UqListItem>
            <UqListItem dense>
              <UqListItemIcon>
                <InfoRoundedIcon sx={{ color: theme => theme.palette.text.disabled }} />
              </UqListItemIcon>
              <UqListItemText
                primary={t('summary_meeting_info_primary')}
                secondary={t('summary_meeting_info_secondary')}
                primaryTypographyProps={{ color: 'text.primary' }}
                secondaryTypographyProps={{ color: 'text.secondary' }}
              />
            </UqListItem>
            <UqListItem dense>
              <UqListItemIcon>
                <CalendarTodayRoundedIcon sx={{ color: theme => theme.palette.text.disabled }} />
              </UqListItemIcon>
              <UqListItemText
                primary={`${date} ${t('summary_meeting_time')} ${time}`}
                primaryTypographyProps={{ color: 'text.primary' }}
              />
            </UqListItem>
            <UqListItem dense alignItems="flex-start">
              <UqListItemIcon>
                <VideocamRoundedIcon sx={{ color: theme => theme.palette.text.disabled }} />
              </UqListItemIcon>
              <UqListItemText
                primary={t('summary_info_primary')}
                secondary={t('summary_info_secondary')}
                primaryTypographyProps={{ color: 'text.primary' }}
                secondaryTypographyProps={{ color: 'text.secondary' }}
              />
            </UqListItem>
          </UqList>
        </UqBox>
        <UqTypography variant="h6" color="text.primary" align="center" marginTop={24}>
          {t('summary_title')}
        </UqTypography>
        <UqTextField
          name="fname"
          autoComplete="given-name"
          value={firstName}
          onChange={e =>
            dispatch(
              updateBookingDetails({
                key: 'firstName',
                value: e.target.value
              })
            )
          }
          error={firstNameValid ? true : false}
          helperText={firstNameValid}
          label={t('summary_input_firstname')}
          variant="outlined"
          size="medium"
          fullWidth
          marginTop={24}
          marginBottom={8}
        />
        <UqTextField
          name="lname"
          autoComplete="family-name"
          value={lastName}
          onChange={e =>
            dispatch(
              updateBookingDetails({
                key: 'lastName',
                value: e.target.value
              })
            )
          }
          error={lastNameValid ? true : false}
          helperText={lastNameValid}
          label={t('summary_input_lastname')}
          variant="outlined"
          size="medium"
          fullWidth
          marginVertical={8}
        />
        <UqTextField
          name="email"
          autoComplete="email"
          value={email}
          onChange={e =>
            dispatch(
              updateBookingDetails({
                key: 'email',
                value: e.target.value
              })
            )
          }
          error={emailValid ? true : false}
          helperText={emailValid}
          label={t('summary_input_email')}
          variant="outlined"
          size="medium"
          fullWidth
          marginVertical={8}
        />
        <UqTextField
          type="tel"
          name="phone"
          autoComplete="tel"
          value={phone}
          onChange={e =>
            dispatch(
              updateBookingDetails({
                key: 'phone',
                value: e.target.value
              })
            )
          }
          error={phoneValid ? true : false}
          helperText={phoneValid}
          label={t('summary_input_phone')}
          variant="outlined"
          size="medium"
          fullWidth
          marginTop={8}
          marginBottom={16}
        />
        <UqFormGroup>
          <UqFormControlLabel
            control={
              <UqSwitch
                checked={messageOpen}
                onChange={e =>
                  dispatch(
                    updateBookingDetails({
                      key: 'messageOpen',
                      value: e.target.checked
                    })
                  )
                }
                size="medium"
                color="secondary"
              />
            }
            label={t('summary_wishes')}
          />
        </UqFormGroup>
        <UqCollapse in={messageOpen} timeout="auto" unmountOnExit>
          <UqTextField
            label={t('summary_input_wishes_label')}
            placeholder={t('summary_input_wishes_placeholder')}
            value={message}
            onChange={e =>
              dispatch(
                updateBookingDetails({
                  key: 'message',
                  value: e.target.value
                })
              )
            }
            variant="outlined"
            size="medium"
            fullWidth
            rows={3}
            multiline
            marginVertical={8}
          />
        </UqCollapse>
        <UqFormGroup marginVertical={8}>
          <UqFormControlLabel
            control={
              <UqSwitch
                checked={participantsOpen}
                onChange={e =>
                  dispatch(
                    updateBookingDetails({
                      key: 'participantsOpen',
                      value: e.target.checked
                    })
                  )
                }
                size="medium"
                color="secondary"
              />
            }
            label={t('summary_participants')}
          />
        </UqFormGroup>
        <UqCollapse in={participantsOpen} timeout="auto" unmountOnExit>
          <UqTextField
            label={t('summary_input_participants')}
            value={participants}
            onChange={e =>
              dispatch(
                updateBookingDetails({
                  key: 'participants',
                  value: e.target.value
                })
              )
            }
            variant="outlined"
            size="medium"
            fullWidth
            helperText={t('summary_helper_participants')}
            rows={3}
            multiline
            marginTop={8}
            marginBottom={16}
          />
        </UqCollapse>
      </Layout>
    </>
  )
}

export default Summary
