import { createTheme } from '@mui/material/styles'
import { UqTheme } from '@uniqore/theme'

const theme = createTheme({
  ...UqTheme,
  palette: {
    primary: {
      main: '#002c38',
      light: '#305562',
      dark: '#000112'
    },
    secondary: {
      main: '#43a047',
      light: '#76d275',
      dark: '#00701a'
    },
    error: {
      main: '#EA4274',
      light: '#ff78a2',
      dark: '#b20049'
    },
    info: {
      main: '#44ADFF',
      light: '#83dfff',
      dark: '#007ecb'
    },
    warning: {
      main: '#FF993F',
      light: '#ffca6e',
      dark: '#c76a07'
    },
    success: {
      main: '#76D275',
      light: '#a9ffa5',
      dark: '#43a047'
    },
    common: {
      black: '#212121',
      white: '#FFFFFF'
    },
    text: {
      primary: '#002c38',
      secondary: '#002c3899',
      disabled: '#002c3861'
    }
  },
  typography: {
    h5: {
      fontWeight: 300
    },
    h6: {
      fontWeight: 300
    },
    overline: {
      textTransform: 'uppercase'
    },
    button: {
      textTransform: 'none',
      fontWeight: 400
    }
  },
  custom: {
    borderColor: '#E6EAEB'
  }
})

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      borderColor: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom: {
      borderColor: string
    }
  }
}

export default theme
