import React, { useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { Route, Routes, Navigate } from 'react-router-dom'
import { UqBackdrop, UqCircularProgress } from '@uniqore/wrapper'
import Onboarding from 'Components/BookingFlow/Onboarding'
import DateSelection from 'Components/BookingFlow/DateSelection'
import TimeSelection from 'Components/BookingFlow/TimeSelection'
import Summary from 'Components/BookingFlow/Summary'
import Confirmation from 'Components/BookingFlow/Confirmation'
import { editSnackbar } from 'Features/snackbar'
import { initAvailableDates } from 'Features/airtableData'

const GET_DATES = gql`
  query getDates($id: ID!) {
    fetchDataview(id: $id)
  }
`

const BookingFlow: React.FC = () => {
  const dispatch = useDispatch()

  const { data, loading } = useQuery(GET_DATES, {
    variables: { id: process.env.REACT_APP_GET_DATES },
    fetchPolicy: 'network-only',
    onError(error) {
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  })

  useEffect(() => {
    if (data) {
      if (data.fetchDataview) {
        dispatch(initAvailableDates(data.fetchDataview.data.records))
      }
    }
  }, [data])

  return (
    <>
      <Routes>
        <Route element={<Onboarding />} path="/:lang" />
        <Route element={<DateSelection />} path="/:lang/date" />
        <Route element={<TimeSelection />} path="/:lang/time" />
        <Route element={<Summary />} path="/:lang/summary" />
        <Route element={<Confirmation />} path="/:lang/confirmation" />
        <Route path="*" element={<Navigate to="/fi" replace />} />
      </Routes>
      <UqBackdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <UqCircularProgress color="inherit" />
      </UqBackdrop>
    </>
  )
}

export default BookingFlow
