import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Feedback from 'Components/FeedbackFlow.tsx/Feedback'
import BookingFlow from 'Components/BookingFlow/BookingFlow'
import Snackbar from 'Components/Common/Snackbar'

const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<BookingFlow />} path="*" />
          <Route element={<Feedback />} path="/:lang/feedback/:engagement" />
        </Routes>
      </BrowserRouter>
      <Snackbar />
    </>
  )
}
export { App }
export default App
