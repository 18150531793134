import React, { useEffect, useState } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { Layout, RadioButtonGroup, RadioButtonGroupSchema } from '@uniqore/module'
import { UqTypography } from '@uniqore/wrapper'
import NavBar from 'Components/Common/NavBar'
import { updateBookingDetails } from 'Features/bookingDetails'

const TimeSelection: React.FC = () => {
  const { t } = useTranslation()
  const { lang } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { availableDates } = useTypedSelector(state => state.airtableData)
  const { date, time } = useTypedSelector(state => state.bookingDetails)

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const [times, setTimes] = useState<RadioButtonGroupSchema[]>([])

  const thisDate = availableDates[date]

  // Clear time values when this page renders
  useEffect(() => {
    dispatch(
      updateBookingDetails({
        key: 'airtableAvailabilityRowId',
        value: ''
      })
    )
    dispatch(updateBookingDetails({ key: 'time', value: '' }))
  }, [])

  useEffect(() => {
    if (!thisDate) {
      navigate(`/${lang}/date`)
    } else {
      const handleTimes: RadioButtonGroupSchema[] = []
      Object.keys(thisDate).forEach(value => {
        handleTimes.push({
          text: value,
          value
        })
      })
      setTimes(handleTimes)
    }
  }, [thisDate])

  const handleChange = (value: string) => {
    dispatch(
      updateBookingDetails({
        key: 'airtableAvailabilityRowId',
        value: thisDate[value].airtableRowId
      })
    )
    dispatch(updateBookingDetails({ key: 'time', value }))
    navigate(`/${lang}/summary`)
  }

  return (
    <Layout
      containerBackground="#ECF6ED"
      top={<NavBar text={date} navigateTo={`/${lang}/date`} progressValue={66} />}
    >
      <UqTypography variant="h6" color="text.primary" align="center">
        {t('time_selection_title')}
      </UqTypography>
      <UqTypography variant="body2" color="text.secondary" align="center">
        {t('time_selection_desc')}
      </UqTypography>
      <RadioButtonGroup
        marginVertical={16}
        value={time}
        onChange={handleChange}
        radioButtons={times}
        variant="outlined"
        buttonsColor="secondary"
      />
    </Layout>
  )
}

export default TimeSelection
