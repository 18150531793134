import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface BookingDetails {
  date: string
  time: string
  firstName: string
  lastName: string
  email: string
  phone: string
  participants: string
  message: string
  airtableAvailabilityRowId: string
  participantsOpen: boolean
  messageOpen: boolean
  bookingFinished: boolean
}

export interface BookingDetailsFormSchema
  extends Omit<BookingDetails, 'participantsOpen' | 'messageOpen' | 'bookingFinished'> {
  language: string
}

const initialState: BookingDetails = {
  date: '',
  time: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  participants: '',
  message: '',
  airtableAvailabilityRowId: '',
  participantsOpen: false,
  messageOpen: false,
  bookingFinished: false
}

const bookingDetailsSlice = createSlice({
  name: 'bookingDetails',
  initialState,
  reducers: {
    updateBookingDetails: (
      state,
      action: PayloadAction<{ key: keyof BookingDetails; value: string | boolean | number }>
    ) => {
      const { key, value } = action.payload
      if (key === 'participantsOpen' || key === 'messageOpen' || key === 'bookingFinished') {
        state[key] = value as boolean
      } else {
        state[key] = value as string
      }
    }
  }
})

export const { updateBookingDetails } = bookingDetailsSlice.actions
export default bookingDetailsSlice.reducer
