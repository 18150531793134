import React, { useEffect, useState } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import fiLocale from 'date-fns/locale/fi'
import enLocale from 'date-fns/locale/en-US'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { Layout } from '@uniqore/module'
import { UqTypography, UqStaticDatePicker, UqTextField } from '@uniqore/wrapper'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import NavBar from 'Components/Common/NavBar'
import { updateBookingDetails } from 'Features/bookingDetails'
import { format, parse, compareAsc } from 'date-fns'

const localeMap = {
  fi: fiLocale,
  en: enLocale
}

const DateSelection: React.FC = () => {
  const { t } = useTranslation()
  const { lang } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { availableDates } = useTypedSelector(state => state.airtableData)
  const { date } = useTypedSelector(state => state.bookingDetails)

  useEffect(() => {
    if (lang === 'fi' || lang === 'en') {
      i18n.changeLanguage(lang)
    } else {
      i18n.changeLanguage('fi')
      navigate(`/fi/date`)
    }
  }, [lang])

  // Clear date value when this page renders
  useEffect(() => {
    dispatch(updateBookingDetails({ key: 'date', value: '' }))
  }, [])

  const [dates, setDates] = useState<Date[]>([])

  useEffect(() => {
    const handleDates: Date[] = []
    Object.keys(availableDates).forEach(availableDate => {
      const parsed = parse(availableDate, 'dd.MM.yyyy', new Date())
      handleDates.push(parsed)
    })
    setDates(handleDates)
  }, [availableDates])

  const handleChange = (newDate: Date) => {
    const value = format(newDate, 'dd.MM.yyyy')
    dispatch(updateBookingDetails({ key: 'date', value }))
    navigate(`/${lang}/time`)
  }

  return (
    <Layout
      containerBackground="#ECF6ED"
      top={
        <NavBar
          text={t('date_selection_navbar')}
          navigateTo={`/${lang}`}
          showLanguageChange
          progressValue={33}
        />
      }
    >
      <UqTypography variant="h6" color="text.primary" align="center">
        {t('date_selection_title')}
      </UqTypography>
      <UqTypography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ marginBottom: '16px' }}
      >
        {t('date_selection_desc')}
      </UqTypography>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={localeMap[i18n.language as 'fi' | 'en']}
      >
        <UqStaticDatePicker
          value={parse(date, 'dd.MM.yyyy', new Date())}
          onChange={newValue => {
            handleChange(newValue)
          }}
          showDaysOutsideCurrentMonth
          disableHighlightToday
          displayStaticWrapperAs="desktop"
          openTo="day"
          renderInput={params => <UqTextField {...params} />}
          renderDay={(day: Date, _selectedDays, PickersDayProps) => {
            let isSelectable = false
            for (const validDate of dates) {
              if (compareAsc(day, validDate) === 0) {
                isSelectable = true
                break
              }
            }
            return (
              <PickersDay
                {...PickersDayProps}
                disabled={!isSelectable}
                sx={
                  isSelectable
                    ? {
                        border: '1px solid black'
                      }
                    : {}
                }
              />
            )
          }}
        />
      </LocalizationProvider>
    </Layout>
  )
}

export default DateSelection
